import { dew as _utilsDew } from "./utils";
import { dew as _supportDew } from "./support";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var utils = _utilsDew();

  var support = _supportDew(); // private property


  var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="; // public method for encoding

  exports.encode = function (input) {
    var output = [];
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0,
        len = input.length,
        remainingBytes = len;
    var isArray = utils.getTypeOf(input) !== "string";

    while (i < input.length) {
      remainingBytes = len - i;

      if (!isArray) {
        chr1 = input.charCodeAt(i++);
        chr2 = i < len ? input.charCodeAt(i++) : 0;
        chr3 = i < len ? input.charCodeAt(i++) : 0;
      } else {
        chr1 = input[i++];
        chr2 = i < len ? input[i++] : 0;
        chr3 = i < len ? input[i++] : 0;
      }

      enc1 = chr1 >> 2;
      enc2 = (chr1 & 3) << 4 | chr2 >> 4;
      enc3 = remainingBytes > 1 ? (chr2 & 15) << 2 | chr3 >> 6 : 64;
      enc4 = remainingBytes > 2 ? chr3 & 63 : 64;
      output.push(_keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4));
    }

    return output.join("");
  }; // public method for decoding


  exports.decode = function (input) {
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0,
        resultIndex = 0;
    var dataUrlPrefix = "data:";

    if (input.substr(0, dataUrlPrefix.length) === dataUrlPrefix) {
      // This is a common error: people give a data url
      // (data:image/png;base64,iVBOR...) with a {base64: true} and
      // wonders why things don't work.
      // We can detect that the string input looks like a data url but we
      // *can't* be sure it is one: removing everything up to the comma would
      // be too dangerous.
      throw new Error("Invalid base64 input, it looks like a data url.");
    }

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    var totalLength = input.length * 3 / 4;

    if (input.charAt(input.length - 1) === _keyStr.charAt(64)) {
      totalLength--;
    }

    if (input.charAt(input.length - 2) === _keyStr.charAt(64)) {
      totalLength--;
    }

    if (totalLength % 1 !== 0) {
      // totalLength is not an integer, the length does not match a valid
      // base64 content. That can happen if:
      // - the input is not a base64 content
      // - the input is *almost* a base64 content, with a extra chars at the
      //   beginning or at the end
      // - the input uses a base64 variant (base64url for example)
      throw new Error("Invalid base64 input, bad content length.");
    }

    var output;

    if (support.uint8array) {
      output = new Uint8Array(totalLength | 0);
    } else {
      output = new Array(totalLength | 0);
    }

    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = enc1 << 2 | enc2 >> 4;
      chr2 = (enc2 & 15) << 4 | enc3 >> 2;
      chr3 = (enc3 & 3) << 6 | enc4;
      output[resultIndex++] = chr1;

      if (enc3 !== 64) {
        output[resultIndex++] = chr2;
      }

      if (enc4 !== 64) {
        output[resultIndex++] = chr3;
      }
    }

    return output;
  };

  return exports;
}
import { dew as _GenericWorkerDew } from "./stream/GenericWorker";
import { dew as _flateDew } from "./flate";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var GenericWorker = _GenericWorkerDew();

  exports.STORE = {
    magic: "\0\0",
    compressWorker: function (compressionOptions) {
      return new GenericWorker("STORE compression");
    },
    uncompressWorker: function () {
      return new GenericWorker("STORE decompression");
    }
  };
  exports.DEFLATE = _flateDew();
  return exports;
}